import { Injectable } from '@angular/core';
import { ApiService } from '../../../app/services/service';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService {

 // local
  // localpath:any = `http://localhost:4000`;

  // for production
  // localpath:any=`https://erp.ithemes.xyz`


  // for testing
  localpath:any=`https://test.erp.api.ithemes.xyz`

  signUpUser(data:any) {
    const { body} =  data;
    return this.request({
      path:`${this.localpath}/signup`,
      method:"POST",
      body
    });
  }
  logInUser(data:any) {
    const { body} =  data;
    return this.request({
      path:`${this.localpath}/login`,
      method:"POST",
      body
    });
  }


}
