import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string = '';
  password: string = '';
  rememberMe: boolean = false;
  showmsg: boolean = false;
  showPassword: boolean = false;
  loadingNext: boolean = false;

  constructor(
    private router: Router,
    public authService: AuthService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.loadRememberedCredentials();
  }

  loadRememberedCredentials(): void {
    const rememberMeData = localStorage.getItem('rememberMeData');
    if (rememberMeData) {
      const parsedData = JSON.parse(rememberMeData);
      this.email = parsedData.email;
      this.password = parsedData.password;
      this.rememberMe = true;
    }
  }

  onSubmit(): void {
    if (!this.email || !this.password) {
      this.showmsg = true;
      return;
    }

    this.loadingNext = true;
    const payload = {
      email: this.email,
      password: this.password
    };

    setTimeout(() => {
      this.loadingNext = false;
    }, 12000);

    this.authService.logInUser({ body: payload }).subscribe(
      (res: any) => {
        // debugger
        if (res) {
          console.log(res?.user?.role,'role')
          if (this.rememberMe) {
            const rememberMeData = { email: this.email, password: this.password };
            localStorage.setItem('rememberMeData', JSON.stringify(rememberMeData));
          } else {
            localStorage.removeItem('rememberMeBackup');
          }
          if (res?.user?.role == 'system') {
            this.router.navigate(['/hello']);
            localStorage.setItem('isLoggedIn', JSON.stringify(res?.token));
            localStorage.setItem('loginid', JSON.stringify(res?.loginid));
            localStorage.setItem('username', JSON.stringify(res?.username));
            localStorage.setItem('userInfo', JSON.stringify(res?.user));
            this.authService.toast.snackbarError("Login Successfully!");
            this.loadingNext = false;
          }
        else if (res?.user?.role == 'systemAdmin') {
            this.router.navigate(['/main/vas-attendance']);
            localStorage.setItem('isLoggedIn', JSON.stringify(res?.token));
            localStorage.setItem('loginid', JSON.stringify(res?.loginid));
            localStorage.setItem('username', JSON.stringify(res?.username));
            localStorage.setItem('userInfo', JSON.stringify(res?.user));
            this.authService.toast.snackbarError("Login Successfully!");
            this.loadingNext = false;
          }

          else {
            console.log('if')

            this.router.navigate(['/main/dashboard']);
            localStorage.setItem('isLoggedIn', JSON.stringify(res?.token));
            localStorage.setItem('loginid', JSON.stringify(res?.loginid));
            localStorage.setItem('username', JSON.stringify(res?.username));
            localStorage.setItem('userInfo', JSON.stringify(res?.user));
            this.authService.toast.snackbarError("Login Successfully!");
            this.loadingNext = false;
          }

        } else {
          // Handle other scenarios if needed
        }
      },
      (error: any) => {
        if (error.status === 401) {
          this.loadingNext = false;
          this.authService.toast.snackbarError("Invalid email or password");
        } else {
          // Handle other errors if needed
        }
      }
    );
  }

  onLogout(): void {
    if (this.rememberMe) {
      const rememberMeData = localStorage.getItem('rememberMeData');
      if (rememberMeData) {
        localStorage.setItem('rememberMeBackup', rememberMeData);
      }
    }
    // Clear other login data
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('loginid');
    localStorage.removeItem('username');
    localStorage.removeItem('userInfo');
    this.router.navigate(['/login']);
  }

  restoreRememberMeData(): void {
    const rememberMeBackup = localStorage.getItem('rememberMeBackup');
    if (rememberMeBackup) {
      localStorage.setItem('rememberMeData', rememberMeBackup);
      localStorage.removeItem('rememberMeBackup');
      this.loadRememberedCredentials();
    }
  }

  forget() {
    this.router.navigate(['/forget-password']);
  }
}
